<template>
<div id="page-sucursales">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Facturas" class="elevation-1 px-5 py-3">
          <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>                            
            <v-row>
              <v-col sm="4" md="4" lg="4">
                  <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event"
                        @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                  <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event"
                        @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu2 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col> 
              <v-col sm="4" md="4" lg="4">
                  <v-autocomplete
                        v-model="model.id_cliente" :items="clientes" :hide-no-data="true"
                        :loading="isLoadingClientes"
                        clearable
                        item-text="nombre" item-value="_id"
                        label="Buscar por Cliente">
                    </v-autocomplete>
              </v-col>                           
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.native="buscar_facturas()">
          <v-icon>search</v-icon> Consultar
        </v-btn>
      </v-card-actions>

          <v-card-text>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="fecha_timbrado" slot-scope="props">
                  {{props.row.fecha_timbrado.substr(0,10)}}
                </template>

                <template slot="subtotal" slot-scope="props">
                  <div style="text-align:right;">${{formatNumberDec(props.row.subtotal,2)}}</div>
                </template>

                <template slot="total" slot-scope="props">
                  <div style="text-align:right;">${{formatNumberDec(props.row.total,2)}}</div>
                </template>

                <template slot="fecha" slot-scope="props">
                  {{props.row.fecha.substr(0,10)}}
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="purple" v-if="props.row.estatus=='Facturada'" @click.native="mostrar_correo(props.row.id_facturacion, props.row.cliente.rfc)" title="Enviar Por Correo" v-tippy>
                      <v-icon>send</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="success" v-if="props.row.estatus=='Facturada'" @click.native="get_file(props.row.id_facturacion + '.xml')" title="Descargar XML" v-tippy>
                      <v-icon>code</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="error" v-if="props.row.estatus=='Facturada'" @click.native="get_file(props.row.id_facturacion + '.pdf')" title="Descargar PDF" v-tippy>
                      <v-icon>picture_as_pdf</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="warning" v-if="props.row.estatus=='Facturada'" @click.native="modal_cancelar=true; id_solicitud_cancelado = props.row.id_facturacion; id_venta_cancelado = props.row._id;" title="Cancelar Factura" v-tippy>
                      <v-icon>cancel</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="black" v-if="props.row.estatus=='En Proceso'" @click.native="consultar_cancelada(props.row)" title="Consultar Estatus CFDI" v-tippy>
                      <v-icon>refresh</v-icon>
                    </v-btn>
                    <!-- Se oculta porque ya se hace la nota de credito en el reporte de Diferencias -->
                    <!--v-btn x-small fab dark color="orange" v-if="props.row.estatus=='Facturada'" @click.native="crear_nota(props.row.id_facturacion, props.row._id)" title="Crear Nota de Crédito" v-tippy>
                      <v-icon>note</v-icon>
                    </v-btn-->
                  </v-speed-dial>
                </template>
              </v-client-table>
              
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modalCorreo" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">email</v-icon>&nbsp;
          <span class="subheading">
            <strong>Enviar por Correo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalCorreo = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcorreo" lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field label="Enviar a" v-model="correo.correos" persistent-hint hint="Correos separados por coma" :rules="[rules.required, validaCorreo]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Asunto" v-model="correo.asunto" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Texto del Correo" v-model="correo.mensaje" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Responder a" v-model="correo.responder_a" persistent-hint hint="Correos separados por coma" :rules="[rules.required, validaCorreo]"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn small @click.native="modalCorreo = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn small color="success" @click.native="enviarCorreo()">
                <v-icon>done</v-icon> Enviar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>
  <v-dialog v-model="modal_cancelar" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">Cancelar CFDI</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="formempresa">
            <v-card width="100%">
              <v-card-text>
                <h5>¿Realmente desea cancelar el CFDI?</h5>
                <v-autocomplete v-model="motivo" label="Debe especificar el Motivo de Cancelación" :items="motivos" :hide-no-data="true" item-text="nombre" item-value="clave">
                </v-autocomplete>

                <v-text-field v-if="motivo=='01'" label="Folio Sustituto" v-model="folio_sustituto" :rules="[rules.required, validaFolio]" />
              </v-card-text>
            </v-card>

          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" @click="modal_cancelar=false">
          <v-icon small>mdi-cancel</v-icon> NO
        </v-btn>
        <v-btn color="success" @click="cancelar_factura(id_solicitud_cancelado, id_venta_cancelado)" :disabled="motivo == ''">
          <v-icon small>mdi-check-circle-outline</v-icon> SI
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.facturas.consultar')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha_de = today;
    this.model.fecha_a = today;    
    this.get_clientes();
    this.buscar_facturas();
  },
  data() {
    return {
      model_egreso:{},
      menu1:false,
      menu2:false,
      isLoadingClientes:false,
      clientes:[],

      motivos: [{
          clave: "01",
          nombre: "Comprobantes emitidos con errores con relación"
        },
        {
          clave: "02",
          nombre: "Comprobantes emitidos con errores sin relación"
        },
        {
          clave: "03",
          nombre: "No se llevó a cabo la operación"
        },
        {
          clave: "04",
          nombre: "Operación nominativa relacionada en una factura global"
        }
      ],
      id_venta_cancelado: "",
      id_solicitud_cancelado: "",
      motivo: "",
      modal_cancelar: false,
      folio_sustituto: "",
      api: 'https://ozu2hub36m.execute-api.us-east-1.amazonaws.com/dev/',
      model_filters: "",
      
      fab: [],
      name: "datagrid",
      fechaUsuario: "",
      id_solicitud_correo: "",
      columns: [
        "tipo_venta",
        "sucursal.nombre",
        "ticket",
        "cliente.nombre",
        "subtotal",
        "total",
        "fecha",
        "fecha_timbrado",
        "estatus",
        "actions"
      ],
      show_loading: true,
      options: {
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["tipo_venta", "sucursal.nombre", "ticket", "cliente.nombre", "subtotal", "total", "fecha", "fecha_timbrado", "estatus"],
        sortable: ["tipo_venta", "sucursal.nombre", "ticket", "cliente.nombre", "subtotal", "total", "fecha", "fecha_timbrado", "estatus"],

        headings: {
          "tipo_venta": "Tipo de Venta",
          "sucursal.nombre": "Sucursal",
          "ticket": "Ticket",
          "cliente.nombre": "Cliente",
          "subtotal": "SubTotal",
          "total": "Total",
          "fecha": "Fecha Venta",
          "fecha_timbrado": "Fecha Timbrado",
          "estatus": "Estatus",
          "actions": "Operaciones"
        },
        listColumns: {
            estatus: [{
                    id: 'Facturada',
                    text: "Facturada"
                },
                {
                    id: 'En Proceso',
                    text: "En Proceso de Cancelación"
                }
            ]
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Facturas",
          href: ""
        }
      ],
      model: {
        fecha_de: "",
        fecha_a: "",
        id_cliente: "",        
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },
      db_config: {
      headers: {
        Authorization: "Basic YWRtaW46YWRtaW4xMjMq"
      }
    },
      modalCorreo: false,
      correo: {
        correos: "",
        responder_a: "labodeguitadeguadalupe@gmail.com",
        asunto: "La Bodeguita - Facturación",
        mensaje: "Se adjunta PDF y XML de su factura. Gracias por su compra."
      },

    };
  },
  methods: {
    validaFolio: function(val) {
      const pattern =  /^[a-f0-9A-F]{8}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{12}$/;
      if (pattern.test(val)) {
        return true;
      } else {
        return "Folio mal formado";
      }
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    get_clientes() {
      this.isLoadingClientes = true
      let data = {
            "selector": {
                "type":"clientes",
                "estatus": "Activo"
            },
        };

    window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
        .then(response => {
            if (response.data.docs.length > 0){
                this.clientes = response.data.docs;
            }else
                this.clientes = [];
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener los clientes.",
                footer: ""
            });
        }).then(()=>{ this.isLoadingClientes=false; });
    },
    buscar_facturas: function(){
      window.dialogLoader.show('Espere un momento por favor..');
      var where = {
        //"type": "ventas",  //Ya lo filtra el indice
        "fecha": {
                "$gte": this.model.fecha_de,
                "$lte": this.model.fecha_a+ "T23:59:59.999"
            },
        //"estatus": "Facturada", //Ya lo filtra el indice
        "cliente._id":{"$exists":true}
        }      

      if(this.model.id_cliente && this.model.id_cliente != ""){
        where["cliente._id"] = this.model.id_cliente;
      }

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: where,
            sort: [{"fecha":"desc"}],
            use_index: "_design/90fbf9ff82d7d77b257b1628ce6f38c028fdb8b7"
          }
        }).then(response => {
          if (response.data.docs.length) {
            response.data.docs.forEach(function(e) {
              if (!e.fecha_timbrado){
                e.fecha_timbrado = "";
              }
              //Si es una venta timbrada en proceso de cancelación, se cambia su estatus a En Proceso
              if (e.estatus_cancelado && e.estatus_cancelado=="En Proceso"){
                e.estatus = "En Proceso";
              }
                
            });
            
            this.registros.items = response.data.docs            
          } else {
            this.registros.items = [];
          }
        })
        .catch(error => {
          console.log(error);
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros. Intente nuevamente",
            footer: ""
          });
          
        }).then(()=>{
          window.dialogLoader.hide();
        });
    },    
    get_file: function(key) {

      var self = this;
      var data_xml = {
        "key": "BGU960207S5A/" + key
      };
      let url = this.api + 'carta_porte/get_download_url_mongo/';

      window.dialogLoader.show('Espere un momento por favor..');

      axios.post(url, data_xml)
        .then((res) => {
          window.dialogLoader.hide();
          if (typeof res.data !== 'undefined') {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = res.data;
            a.target = "_blank";
            a.click();
            a.parentNode.removeChild(a);
          }
        })
        .catch((error) => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el archivo. Intente nuevamente por favor.",
            footer: ""
          });
          window.dialogLoader.hide();
          console.log("error al bajar el xml", error);
        });

    },
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            return "";
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    cancelar_factura: function(id_facturacion, id_venta) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de cancelar la factura?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        var url = this.api + "carta_porte/cancelar_mongo/";
        var url_test = "http://localhost:8000/carta_porte/cancelar_mongo/";
        var params = {
          "id_solicitud": id_facturacion,
          "motivo": this.motivo
        };

        if (this.motivo == '01') {
          params.folio_sustituto = this.folio_sustituto;
        }
        var url_update = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/factura_cancelar/" + id_venta;

        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .post(url, params)
            .then(response => {
              console.log(response);

              window.axios
                .put(url_update, {
                  "estatus": "Facturada",//SE DEJA EN FACTURADA, Y HASTA QUE EL METODO DE CONSULTAR CANCELADA REGRESE INFO YA SE CAMBIA A FINALIZADA
                  "fecha_cancelado": window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                  "estatus_cancelado":"En Proceso" //En Proceso|Cancelado
                })
                .then(response => {
                  if (response.data == true) {
                    window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                      color: 'success'
                    });
                  } else {
                    console.log("no se pudo actualizar la venta")
                  }
                })
                .catch(error => {
                  console.log("error", error);
                }).then(() => {
                  window.dialogLoader.hide();
                  this.buscar_facturas();
                  this.modal_cancelar = false;
                  this.folio_sustituto = null;
                  this.motivo = null;
                  this.id_solicitud_cancelado = null;
                  this.id_venta_cancelado = null;
                });
            })
            .catch(error => {              
              this.modal_cancelar = false;
              this.folio_sustituto = null;
              this.motivo = null;
              this.id_solicitud_cancelado = null;
              this.id_venta_cancelado = null;
              window.dialogLoader.showSnackbar('Ocurrio un error al cancelar...', {
                color: 'error'
              });
              window.dialogLoader.hide();

            });

        }

      });
    },
    consultar_cancelada: function(row){
      window.dialogLoader.show('Espere un momento por favor..');
      var self = this;

      var data = {
        "id_solicitud": row.id_facturacion,
      };
      axios.post(self.api + 'carta_porte/consultar_estatus_mongo/', data)
        .then((res) => {
          if (typeof res.data !== 'undefined') {
            console.log("RESPUESTA CONSULTA: ", res.data);
            self.$swal({
              type: "success",
              title: "¡Operación Exitosa!",
              text: "El registro se consultó correctamente.",
              footer: "Estatus: " + res.data.estatus_cancelacion,
            });
            
            window.dialogLoader.hide();
            if(res.data.estado && res.data.estatus_cancelacion && res.data.estado =="Cancelado"){
              
                var url_update = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/factura_cancelar/" + row._id;
                window.dialogLoader.show('Espere un momento por favor..');
                  window.axios
                    .put(url_update, {
                      "estatus": "Finalizada",//Se deja como Finalizada, para que ahora si la puedan volver a facturar
                      "fecha_cancelado": window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                      "estatus_cancelado":"Cancelado" //En Proceso|Cancelado
                    })
                    .then(response => {
                      if (response.data == true) {
                        window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                          color: 'success'
                        });
                      } else {
                        console.log("no se pudo actualizar la venta")
                      }
                    })
                    .catch(error => {
                      console.log("error", error);
                    }).then(() => {
                      window.dialogLoader.hide();
                      this.buscar_facturas();
                    }); 
            }
            
          } else {
            window.dialogLoader.hide();
            //console.log("RESPONSE: ", res);
          }
        }).catch((error) => {
          console.log(error);
          window.dialogLoader.hide();
          self.$swal({
            type: "error",
            title: "Error",
            text: "Ocurrió un error al consultar el registro.",
            footer: "Intente nuevamente más tarde",
          });
        }).then(() => {          
          this.buscar_facturas();
        });

    },
    validaCorreo: function(val) {
      if (val != null && val != "") {
        var correos = val.split(",");
        for (var k in correos) {
          var correo = correos[k].toString().trim();
          if (!window.funciones.validaCorreo(correo)) {
            return "Hay un correo no valido. Favor de verificar.";
            break;
          }
        }
      }
      return true;
    },
    mostrar_correo: function(id_solicitud, rfc) {

      this.id_solicitud_correo = id_solicitud;

      window.dialogLoader.show('Espere un momento por favor..');
      let where = {
        "type": "clientes",
        "rfc": {
          "$regex": "(?i)" + rfc
        }
      };

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: where,
            fields: ['rfc', 'email']
          },
        }).then(response => {
          if (response.data.docs.length) {
            this.correo.correos = response.data.docs[0].email;
          } else {
            this.correo.correos = "";
          }
        })
        .then(() => {
          window.dialogLoader.hide();
          this.modalCorreo = true;
        });
    },
    enviarCorreo: function() {
      if (this.$refs.formcorreo.validate()) {
        var self = this;

        window.dialogLoader.show('Espere un momento por favor..');

        var data = {
          id_solicitud: this.id_solicitud_correo, //requerido
          lista_emails: this.correo.correos.replaceAll(" ", "").split(","), //requerido, debe ser un array
          asunto: this.correo.asunto, //opcional
          mensaje: this.correo.mensaje, //opcional
          responder_a: this.correo.responder_a, //opcional
        };

        let url = this.api + 'carta_porte/enviar_factura_mongo/';
        axios.post(url, data)
          .then((res) => {
            window.dialogLoader.showSnackbar('El correo se envió correctamente..', {
              color: 'success'
            });
          })
          .catch((error) => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al enviar el correo. Por favor intente nuevamente.",
              footer: ""
            });
            console.log("error al enviar correo", error);
          }).then(() => {
            window.dialogLoader.hide();
            this.modalCorreo = false;
          });

      }
    },
    crear_nota: async function(id_facturacion, id_venta) {
      //VM2024L00003941
      window.dialogLoader.show('Espere un momento por favor..');

      await this.get_factura_global(id_venta);

      if(this.factura_global == null) {
        window.dialogLoader.hide();

        this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "La venta no se encuentra en una factura global",
              footer: ""
            });
        
        return;
      }

      var uuid_global = null;
      await this.consultar_factura_global().then(r => {
        uuid_global = r.uuid;
      }).catch(er => {
        uuid_global = null;
      });

      if(uuid_global == null) {
        window.dialogLoader.hide();

        this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "No se encontro el UUID de la factura global",
              footer: ""
            });
        
        return;
      } 

      this.id_solicitud_egreso = id_facturacion;

      await this.consultar_estatus_egreso().then(r => {
        console.log("then")
      }).catch(er => {
        window.dialogLoader.hide();
        console.log("err: " + er)
        this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al consultar la factura. Por favor intente nuevamente.",
              footer: ""
            });
        
        return; 
      });

      this.id_solicitud_egreso = null;

      var model_egreso = {
        "rfc": "BGU960207S5A",
        "email": "",
        "json": Object.assign({}, this.model_egreso)
      };

      delete model_egreso.json.Complemento;

      model_egreso.json.TipoDeComprobante = "E";
      model_egreso.json.MetodoPago = "PUE";
      model_egreso.json.FormaPago = "01";

      model_egreso.json.CfdiRelacionados = [{"TipoRelacion": "01", "UUIDS": [uuid_global]}]

      model_egreso.json.Receptor = {
        "Rfc": "XAXX010101000",
        "Nombre": "PÚBLICO EN GENERAL",
        "UsoCFDI": "S01",
        "RegimenFiscalReceptor": "616",
        "DomicilioFiscalReceptor": "98600",
        "Domicilio": {
          "Calle": "",
          "CodigoPostal": "",
          "Colonia": "",
          "Estado": "",
          "Localidad": "",
          "Municipio": "",
          "NumeroExterior": "",
          "Pais": "",
          "Referencia": ""
        }
      };
      model_egreso.json.Conceptos = [{
        "Cantidad": "1",
        "ClaveProdServ": "84111506",
        "ClaveUnidad": "ACT",
        "Descripcion": "Nota",
        "Importe": model_egreso.json.SubTotal,
        "ValorUnitario": model_egreso.json.SubTotal,
        "ObjetoImp": "02",
        "NoIdentificacion": this.model.numero_ticket,
        "Impuestos": {
          "Traslados": model_egreso.json.Impuestos.Traslados,
        }
      }]

      await axios.post(this.api + 'carta_porte/guardar_mongo/', model_egreso).then(async res => {
        if (typeof res.data !== 'undefined') {
          this.id_solicitud_egreso = res.data.id_solicitud;
          var contador = 0;
          var flag = 0;
          var error_timbrado = "";
          var self = this;
          do {
            await new Promise(r => setTimeout(r, 15000));
            await self.consultar_estatus_egreso().then(r => {
              flag = r.estatus;
              error_timbrado = r.error;
            }).catch(er => {
              flag = er;
            });
            console.log(flag);
            contador += 1;
          } while (flag != 1 && flag != 3 && contador < 11);

          console.log(id_venta);
          var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/factura_egreso/" + id_venta;
          if (flag == 1) {
            console.log("facturada egreso");
            await window.axios.create({
                withCredentials: true
              }).put(url, {
                "id_facturacion_egreso": this.id_solicitud_egreso,
                "error_timbrado_egreso": ""
              }, this.db_config)
              .then(response => {
                if (response.data == true) {
                  console.log("updated egreso");
                } else {
                  console.log(response);
                  console.log("error updated egreso")
                }
              })
              .catch(error => {
                console.log("error", error);
              });
              window.dialogLoader.hide();

          } else {
            await window.axios.create({
                withCredentials: true
              }).put(url, {
                "id_facturacion_egreso": this.id_solicitud_egreso,
                "error_timbrado_egreso": this.error_timbrado
              }, this.db_config)
              .then(response => {
                if (response.data == true) {
                  console.log("updated egreso error timbrado");
                  self.$swal({
                    type: "success",
                    title: "¡Operación Exitosa!",
                    text: "Nota creada con exito.",
                    footer: "",
                  });
                } else {
                  console.log("error timbrado updated egreso")
                  self.$swal({
                    type: "error",
                    title: "¡Advertencia!",
                    text: "Ocurrío un error al actualizar la venta. Cominicalo con el administrador",
                    footer: "",
                  });
                }
              })
              .catch(error => {
                self.$swal({
                    type: "error",
                    title: "¡Advertencia!",
                    text: "Ocurrío un error al actualizar la venta. Cominicalo con el administrador",
                    footer: "",
                  });
                console.log("error", error);
              });
          }
          window.dialogLoader.hide();
        }
      }).catch(err => {
        this.$swal({
                    type: "error",
                    title: "¡Advertencia!",
                    text: "Ocurrío un error al actualizar la venta. Cominicalo con el administrador",
                    footer: "",
                  });
        window.dialogLoader.hide();
        console.log(err);
        console.log("error creando egreso");
      });

    },
    consultar_estatus_egreso: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        await axios.post(self.api + 'carta_porte/solicitud_mongo/', {
            "id_solicitud": self.id_solicitud_egreso
          })
          .then((res) => {
            self.model_egreso = res.data.json;
            if (typeof res.data !== 'undefined') {
              if (res.data.estatus == 1) {
                return resolve({
                  "estatus": 1,
                  "error": res.data.error_solicitud
                });
              } else if (res.data.estatus > 1) {
                return resolve({
                  "estatus": 3,
                  "error": res.data.error_solicitud
                });
              } else {
                return resolve({
                  "estatus": 2,
                  "error": res.data.error_solicitud
                });
              }
            } else {
              console.log("en el undefined");
              return resolve({
                "estatus": 2,
                "error": ""
              });
            }
          })
          .catch((error) => {
            console.log("en el catch", error);
            return reject(3);
          });

      });
    },
    get_factura_global: async function(id) {
      let data = {
        "selector": {
          "type": "facturas_globales",
          "estatus": {"$in": ["Error al Cancelar", "Facturado"]},
          "ids": {"$in": [id]}
        },
      };

      await window.axios.create({withCredentials: true})
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, this.db_config)
        .then(async response => {
          if (response.data.docs.length > 0) {
            for (var i = 0; i < response.data.docs.length; i++) {
              if(response.data.docs[i].estatus == "Facturado") {
                this.factura_global = await response.data.docs[i];
                break;
              } else {
                this.factura_global = null;
              }
            }
          } else {
            this.factura_global = null;
          }
        })
        .catch(error => {
          this.factura_global = null;
        });
    },
    consultar_factura_global: function() {
      var self = this;

      return new Promise(async function(resolve, reject) {
        await axios.post(self.api + 'carta_porte/solicitud_mongo/', {
            "id_solicitud": self.factura_global.id_facturacion
          })
          .then((res) => {
            if (typeof res.data !== 'undefined') {
              console.log("encontrada");
              return resolve({
                "uuid": res.data.json.Complemento.TimbreFiscalDigital.UUID
              });
            } else {
              console.log("en el undefined factura global");
              return resolve({
                "uuid": null
              });
            }
          })
          .catch((error) => {
            console.log("en el catch", error);
            return reject(null);
          });

      });
    },
  }
};
</script>
